import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: 'admin', loadChildren: () => import('./admin/routes') },
  { path: '', loadChildren: () => import('./customer/routes') },
  {
    path: '**',
    loadComponent: () => import('./_shared/components/page-not-found/page-not-found.component'),
  },
];
