import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { from, Observable, switchMap } from 'rxjs';

export function authenticationInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const auth = inject(Auth);

  if (auth.currentUser) {
    const jwtToken$ = from(auth.currentUser.getIdToken());
    return jwtToken$.pipe(
      switchMap((jwtToken) => {
        const bearerToken = `Bearer ${jwtToken}`;
        request = request.clone({
          setHeaders: {
            Authorization: bearerToken,
          },
        });
        return next(request);
      }),
    );
  }

  return next(request);
}
